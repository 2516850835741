.menu {
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 30px;
  cursor: pointer;
}

.menu > span {
  width: 100%;
  height: 5px;

  background-color: white;
  z-index: 10;
}

.links {
  display: block;
  position: fixed;
  right: -90vw;
  width: 90vw;
  height: calc(100vh);
  right: 0;
  bottom: 0;
  background-color: black;
  animation: mymove .4s;
  animation-fill-mode: forwards;
}
.active{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.active>.button , .active>button{
  width: 100%;
  border-radius: 0px !important;
  border-color: transparent;
}
.active-links {
display: flex;
flex-direction: column;
padding: 100px 15px 0 15px;
}

.active-links>a{
  padding: 7px 0px 7px ;
  border-top: .1px solid rgba(255, 255, 255, 0.34);
  border-bottom: .1px solid rgba(255, 255, 255, 0.34);
}

@keyframes mymove {
 0%{
  right: -90vw;
 }
 100%{
  right: 0px;
}
}

.notActive {
  display: none !important;
}
