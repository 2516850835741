@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;1,600&display=swap);
.audio {
  display: flex;

  justify-content: center;
  align-items: center;
  position: fixed;

  bottom: 10px;
  right: 20px;
  height: 50px;
  width: 50px;
  background-color: #bd9d00;
  border-radius: 100%;
  cursor: pointer;
}

.Playbtn {
  height: 30px;
  width: 30px;
}

.menu {
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 30px;
  cursor: pointer;
}

.menu > span {
  width: 100%;
  height: 5px;

  background-color: white;
  z-index: 10;
}

.links {
  display: block;
  position: fixed;
  right: -90vw;
  width: 90vw;
  height: calc(100vh);
  right: 0;
  bottom: 0;
  background-color: black;
  -webkit-animation: mymove .4s;
          animation: mymove .4s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.active{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.active>.button , .active>button{
  width: 100%;
  border-radius: 0px !important;
  border-color: transparent;
}
.active-links {
display: flex;
flex-direction: column;
padding: 100px 15px 0 15px;
}

.active-links>a{
  padding: 7px 0px 7px ;
  border-top: .1px solid rgba(255, 255, 255, 0.34);
  border-bottom: .1px solid rgba(255, 255, 255, 0.34);
}

@-webkit-keyframes mymove {
 0%{
  right: -90vw;
 }
 100%{
  right: 0px;
}
}

@keyframes mymove {
 0%{
  right: -90vw;
 }
 100%{
  right: 0px;
}
}

.notActive {
  display: none !important;
}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

.banner{
  height: 100px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.games{
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
}
.game_list{
  background-color: rgba(0, 0, 0, 0.9);
}
header {
  height: calc(100vh /*  - 60px */);
  display: flex;
  justify-content: center;
  align-items: center;
}
.tokens{
  max-width: 1152px;
  color: white;
  font-size: 30px;
  text-align: right;
color: #fdd506;
  margin: 60px auto 0 auto;
}
.red{
  color: #dc572e;
  font-size: 25px;
}
.games-grid {
  display: grid;
  max-width: 1152px;
  height: auto;
  padding: 60px 10px;
  margin: 0 auto 0 auto;
  grid-template-columns: 3fr 2fr;
grid-template-rows: 250px 250px;
grid-gap: 30px;
gap: 30px;
}
.small-image{
  min-height: 200px;
}
@media only screen and (max-width: 700px){ 
  .games{
padding-bottom: 60px;
  
  }
  .tokens{
    padding-right: 30px;
  }

  .games-grid {
    display: grid;

height: auto;
  
    padding: 60px  30px 60px 30px;
    grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
  grid-template-rows: repeat(4, 200px);
  grid-gap: 30px;
  gap: 30px;
  }
  
}

.main-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(/static/media/1.f50fe157.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  grid-area: main;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.text {
  color: white;
}
.text > .title {
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 45px;
}
.text > .info {
  font-size: 18px;
  margin-bottom: 45px;
  width: 50%;
}
.button {
  display: block;
  padding: 13px;
  font-size: 13px;
  text-transform: uppercase;
  background-color: rgb(0, 116, 228);

  color: white;
  width: 200px;
  border-radius: 5px;
  text-align: center;
}
.small-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15px;
}


.a {
  background-image:  linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(/static/media/2.7659dfc1.webp);

}
.b {
  background-image:  linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(/static/media/3.984e4f35.webp);

}
.c {
  background-image:  linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(/static/media/4.e28dad9f.webp);

}
.d {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/static/media/5.14f194b0.webp);

}
.e{
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/static/media/6.b1fba48e.jpg);
}


.small-title{
color:white;
font-size: 25px;
font-weight: bold;
margin-left: 15px;
margin-top: 20px;

}


@-webkit-keyframes reveal {

  0%{
    opacity: 0;
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }

  
  100%{
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  
}


@keyframes reveal {

  0%{
    opacity: 0;
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }

  
  100%{
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  
}
.header_cont{
    height: 100px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 100px;
}

.igc{
    color: white;
    padding: 0 40px;
}

.tokens{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
color: #dc572e;
font-size: 25px;
}

.highlight{
color: #fdd506 ;
}

@media only screen and (max-width: 717px) {
    .header_cont{
        padding: 0 20px;
        justify-content: center;
        
    }

    .login-btn{
        display: none;
    }
  }

.header_name_user{
    color: white;
    padding-left: 15px;
}

.name_logo_cont{
    display: flex;
    align-items: center;
}

.header_image{
    height: 30px;
}

.login_btn{
    height: 30px;
    width: 90px;
    border: none;
    border-radius: 10px;
    background-color: whitesmoke;
}

.header{
    z-index: 100;
}
.about-nav {
  background-color: black;
}
.button.logout{
  background-color: #dc572e !important;
}

.landingPage-header {
  height: 100px;
    padding: 0 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

.auth-landingPage-header {
height: auto;
  padding: 15px 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.auth-landingPage-header a{
  color: white;
  margin:0 10px;

}
.menu{
  display: none;
}
@media only screen and (max-width: 1025px) {
  .links-container{
display: none;
   }
   .menu{
     display: flex;
   }
  .auth-landingPage-header {
    height: 100px;
    padding: 10px 15px;
  }
  .landingPage-header {
    height: 100px;
    padding: 10px 15px;
  }
}
@media only screen and (max-width: 630px) {
  .user_name {
    padding: 10px 45px;
    margin: 0 auto !important;

   
  }
  .landingPage-logo {
    height: 50px !important;
  }
  
  
}

.landingPage-header a {
  color: white;
  margin-right: 15px;
}
.user_name {
  padding: 10px 45px;
  border-radius: 25px;
  margin: 0 auto;
  margin-right: 10px;
  background-color: transparent;
  font-size: 16px;
  color: white;
  border: 2px solid #fdd506;
  text-decoration: none;
}

.logout_btn{
  padding: 10px 45px;
  border-radius: 25px;
  margin: 0 auto;
  margin-left: 20px;
  background-color: transparent;
  font-size: 16px;
  color: white;
  border: 2px solid red;
  text-decoration: none;
  
}


.landingPage-logo {
  height: 60px;
/*   z-index: 2;
  position: relative; */
}


input {
  outline: none;
}
button {
  cursor: pointer;
}
.landing-page-background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(/static/media/5.14f194b0.webp); /* W3C */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-page-background h1 {
  font-size: 70px;
  color: white;
  text-align: center;
}

.landing-page-info {
  max-width: 900px;
  line-height: 1.75;
  margin: 0 auto;
  padding: 60px 30px;
}
.landing-subtitle {
  font-size: 40px;
  text-align: center;
  margin-bottom: 15px;
}

.landingPage-main {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(/static/media/5.14f194b0.webp); /* W3C */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.landingPage-landing-text {
  text-align: center;
  color: #fdd506;
}

.landingPage-landing-text h1 {
  font-size: 150px;
  font-family: "Tangerine", cursive;
  font-weight: 400;
}

.landingPage-banner {
  height: 90px;
  margin: 30px 0;
}

.landingPage-sign-up-button {
  padding: 10px 45px;
  border-radius: 25px;
  background-color: transparent;
  font-size: 16px;
  color: white;
  border: 2px solid #fdd506;
  text-decoration: none;
}

a {
  text-decoration: none;
}

.landingPage-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 120px);
}

.landingPage-input {
  padding: 10px;
  width: 50%;

  margin-right: 10px;
}

.landingPage-submit {
  padding: 11px;
  color: white;
  background-color: #bd9d00;
  outline: none;
  border: none;
}

.lpfooter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 20px 0;
  width: 100%;
  background-color: #dc572e;
}
.lpwidget {
  max-width: 700px;
  padding: 15px;
  margin: 0 auto 15px auto;
  text-align: center;
  font-size: 20px;
}
.social {
  height: 40px;
  width: 40px;
  margin-right: 15px;
}

.lpForm {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 35px;
}

@media only screen and (max-width: 990px) {
  .landingPage-landing-text h1 {
    font-size: 100px !important;
    font-family: "Tangerine", cursive;
    font-weight: 400;
  }
}
@media only screen and (max-width: 600px) {
  .landingPage-landing-text h1 {
    font-size: 80px !important;
    font-family: "Tangerine", cursive;
    font-weight: 400;
  }


  .landingPage-sign-up-button {
    display: none;
  }
  .landingPage-input {
    width: 80vw;
    margin: 15px;
  }
}

@media only screen and (max-width: 700px) and (orientation: landscape) {
  .lpForm {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 500px) {
  .landingPage-landing-text h1 {
    font-size: 60px !important;
  }

  .lpwidget {
    font-size: 18px !important;
  }
}

.about-page-background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(/static/media/5.14f194b0.webp); /* W3C */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 500px;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-page-background > h1 {
  color: white;
  font-size: 60px;
}
.about-info {
  max-width: 1100px;
  line-height: 1.75;
  margin: 0 auto;
  padding: 60px 30px;
}

.about-info div {
  padding: 15px 0;
}
.about-info p {
  padding: 7.5px 0;
}
.about-info h3 {
  font-size: 24px;
}
.about-info h2 {
  font-size: 30px;
  margin-bottom: 15px;
}
.about-contact-form {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 200px 1fr;
  grid-gap: 15px 30px;
  gap: 15px 30px;
  grid-template-areas:
    " a a"
    "b c"
    "d d"
    "e e";
}
.about-contact-form > input,
textarea {
  outline: none;
  text-transform: capitalize;
  resize: none;
}
textarea {
  padding: 10px;
}
.contact-a {
  grid-area: a;
}
.contact-b {
  grid-area: b;
}
.contact-c {
  grid-area: c;
}
.contact-d {
  grid-area: d;
}
.contact-e {
  grid-area: e;
}
.about-contact-form > input {
  padding: 10px;
}

@media only screen and (max-width: 500px) {
  .about-contact-form {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 150px 1fr;
    grid-gap: 15px;
    gap: 15px;
    grid-template-areas:
      " a "
      "b"
      "c"
      "d"
      "e";
  }
}

.sidebar{

 background-color: #191919;
 display: flex;
 flex-direction: column;
 justify-content: space-around;
 align-items: center;
 position: fixed;
 height: auto;

 width: 110px;
 bottom: 20%;

}

.sidebar>a{
    padding: 20px 0;

    transition: .4s all ease;
    cursor: pointer;
}


.sidebar>img:hover{
-webkit-transform: scale(1.2);
        transform: scale(1.2);
}

@media only screen and (max-width: 865px) {
    .sidebar{
        display: none;
    }
}
/* font and color palette */
:root {
  --bg: red;
  --theme: #dc572e;
  --theme-two: #ffffff;
  --text: #697a79;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.main {
  /* include a circle and a triangle elements through SVG shapes, and at either end of the page */

  background-repeat: no-repeat;
  background-position: bottom -150px left -70px, top -120px right -100px;
  background-size: 300px, 380px, 100%;
  font-family: "Poppins", sans-serif;
  color: #697a79;
  color: var(--text);
  min-height: 100vh;
  /* center the main element in the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
}
.main {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(/static/media/5.14f194b0.webp); /* W3C */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
/* include the same shapes included in the background atop the content of the page, with a semi-translucent white
this softens the color of the shapes, but most prominently shows the shapes as if continuing on the underlying content
*/

main.panel {
  /* cap the width of the main panel */
  max-width: 900px;
  width: 90vw;
  background: #ffffff;
  background: var(--theme-two);
  border-radius: 20px;
  box-shadow: 0 2px 5px -6px #697a79, 0 0 30px -50px #697a79;
  box-shadow: 0 2px 5px -6px var(--text), 0 0 30px -50px var(--text);
  /* display the halves side by side */
  display: flex;
  margin: 60px 0;
}

/* for both panels
display the content in a single column layout
*/
main.panel .panel__half {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 2;
  padding: 2rem 1rem;
}
/* styles shared by the halves' elements */
main.panel h2 {
  font-size: 1.9rem;
  text-transform: capitalize;
  margin-bottom: 1rem;
}
main.panel p {
  margin: 1rem 0;
  max-width: 25ch;
  font-size: 0.95rem;
  line-height: 1.5;
  font-weight: 300;
  text-align: center;
}
main.panel a,
main.panel button {
  color: inherit;
  font-family: inherit;
}
main.panel a {
  text-decoration: none;
  margin: 1rem 1rem;
}
/* style the anchor links nesting the social platforms in a circle */
main.panel .links a {
  display: inline-block;
  font-weight: 900;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  border: 1px solid currentColor;
}
/* on hover and on focus switch the color of the links making up the social icons */
main.panel .links a:hover,
main.panel .links a:focus {
  background: #697a79;
  background: var(--text);
  color: #ffffff;
  color: var(--theme-two);
}
/* include a semi-transparent border at the bottom of the anchor link elements which are direct children of the parent
this excludes the social icons */
main.panel .panel__half > a {
  position: relative;
}
main.panel .panel__half > a:after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: 1px dashed currentColor;
  opacity: 0.4;
}
/* display the input elements atop one another */
main.panel form {
  display: flex;
  flex-direction: column;
}
/* widen the input elements to cover a sizeable portion of the panel */
main.panel form input {
  margin: 0.5rem 0;
  width: 100%;
  padding: 1rem 0.75rem;

  border: 1px solid #dc572e;

  border: 1px solid var(--theme);
  color: inherit;
  font-family: inherit;
}
main.panel form input::-webkit-input-placeholder {
  opacity: 0.8;
}
main.panel form input:-ms-input-placeholder {
  opacity: 0.8;
}
main.panel form input::placeholder {
  opacity: 0.8;
}
/* style the buttons to sign in/sign up to have considerable white space _around_ the text */
main.panel button {
  background: none;
  border: 1px solid currentColor;
  border-radius: 50px;
  padding: 0.85rem 2.75rem;
  margin: 2rem 0;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0.1rem;
  width: 100%;
}
/* expand the first half to cover twice the space of the second half */
.panel__half.half--first {
  flex-grow: 2;
  /* remove the border radius for the corners matching the container */
  border-radius: inherit;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.panel__half.half--first button {
  background: #dc572e;
  background: var(--theme);
  color: #ffffff;
  color: var(--theme-two);
}
.panel__half.half--second {
  /* background and color using the theme */
  background: #dc572e;
  background: var(--theme);
  color: #ffffff;
  color: var(--theme-two);
  padding: 2rem;
  flex-grow: 1;
  /* remove the border radius for the corners matching the container */
  border-radius: inherit;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-text {
  height: 40px;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  border: 1px solid #dc572e;
  border: 1px solid var(--theme);
  -webkit-transition: 0.5s;
  color: #494949;
  transition: 0.5s;

  outline: none;
  border-radius: 8px;
  outline: none;
}

.input-text[type="text"]:focus,
.input-text[type="email"]:focus {
  border: 2px solid #3ab19b;
  background-color: whitesmoke;
}

/*
on smaller viewports position the panel atop one another
expand the main panel to cover the entirety of the viewport
*/
@media (max-width: 650px) {
  main.panel {
    flex-direction: column;
    border-radius: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    box-shadow: none;
  }
}

.game{
    margin-bottom: 20px;
}

.game>span{
    margin-bottom: 20px;
}

.gameTitle{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.gameTitle>img{
margin-right: 10px;
}

.divider{
    display: block;
    width: 100%;

    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

video{
    width: 50vw;
    height: 450px;
    margin-top: 30px;
}

@media only screen and (max-width: 865px) {
    video{
        width: 100%;
    }
}
