.about-page-background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../images/5.webp"); /* W3C */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 500px;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-page-background > h1 {
  color: white;
  font-size: 60px;
}
.about-info {
  max-width: 1100px;
  line-height: 1.75;
  margin: 0 auto;
  padding: 60px 30px;
}

.about-info div {
  padding: 15px 0;
}
.about-info p {
  padding: 7.5px 0;
}
.about-info h3 {
  font-size: 24px;
}
.about-info h2 {
  font-size: 30px;
  margin-bottom: 15px;
}
.about-contact-form {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 200px 1fr;
  gap: 15px 30px;
  grid-template-areas:
    " a a"
    "b c"
    "d d"
    "e e";
}
.about-contact-form > input,
textarea {
  outline: none;
  text-transform: capitalize;
  resize: none;
}
textarea {
  padding: 10px;
}
.contact-a {
  grid-area: a;
}
.contact-b {
  grid-area: b;
}
.contact-c {
  grid-area: c;
}
.contact-d {
  grid-area: d;
}
.contact-e {
  grid-area: e;
}
.about-contact-form > input {
  padding: 10px;
}

@media only screen and (max-width: 500px) {
  .about-contact-form {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 150px 1fr;
    gap: 15px;
    grid-template-areas:
      " a "
      "b"
      "c"
      "d"
      "e";
  }
}
