.about-nav {
  background-color: black;
}
.button.logout{
  background-color: #dc572e !important;
}

.landingPage-header {
  height: 100px;
    padding: 0 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

.auth-landingPage-header {
height: auto;
  padding: 15px 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.auth-landingPage-header a{
  color: white;
  margin:0 10px;

}
.menu{
  display: none;
}
@media only screen and (max-width: 1025px) {
  .links-container{
display: none;
   }
   .menu{
     display: flex;
   }
  .auth-landingPage-header {
    height: 100px;
    padding: 10px 15px;
  }
  .landingPage-header {
    height: 100px;
    padding: 10px 15px;
  }
}
@media only screen and (max-width: 630px) {
  .user_name {
    padding: 10px 45px;
    margin: 0 auto !important;

   
  }
  .landingPage-logo {
    height: 50px !important;
  }
  
  
}

.landingPage-header a {
  color: white;
  margin-right: 15px;
}
.user_name {
  padding: 10px 45px;
  border-radius: 25px;
  margin: 0 auto;
  margin-right: 10px;
  background-color: transparent;
  font-size: 16px;
  color: white;
  border: 2px solid #fdd506;
  text-decoration: none;
}

.logout_btn{
  padding: 10px 45px;
  border-radius: 25px;
  margin: 0 auto;
  margin-left: 20px;
  background-color: transparent;
  font-size: 16px;
  color: white;
  border: 2px solid red;
  text-decoration: none;
  
}


.landingPage-logo {
  height: 60px;
/*   z-index: 2;
  position: relative; */
}

