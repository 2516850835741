.game{
    margin-bottom: 20px;
}

.game>span{
    margin-bottom: 20px;
}

.gameTitle{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.gameTitle>img{
margin-right: 10px;
}

.divider{
    display: block;
    width: 100%;

    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

video{
    width: 50vw;
    height: 450px;
    margin-top: 30px;
}

@media only screen and (max-width: 865px) {
    video{
        width: 100%;
    }
}