input {
  outline: none;
}
button {
  cursor: pointer;
}
.landing-page-background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../images/5.webp"); /* W3C */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-page-background h1 {
  font-size: 70px;
  color: white;
  text-align: center;
}

.landing-page-info {
  max-width: 900px;
  line-height: 1.75;
  margin: 0 auto;
  padding: 60px 30px;
}
.landing-subtitle {
  font-size: 40px;
  text-align: center;
  margin-bottom: 15px;
}

.landingPage-main {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../images/5.webp"); /* W3C */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.landingPage-landing-text {
  text-align: center;
  color: #fdd506;
}

.landingPage-landing-text h1 {
  font-size: 150px;
  font-family: "Tangerine", cursive;
  font-weight: 400;
}

.landingPage-banner {
  height: 90px;
  margin: 30px 0;
}

.landingPage-sign-up-button {
  padding: 10px 45px;
  border-radius: 25px;
  background-color: transparent;
  font-size: 16px;
  color: white;
  border: 2px solid #fdd506;
  text-decoration: none;
}

a {
  text-decoration: none;
}

.landingPage-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 120px);
}

.landingPage-input {
  padding: 10px;
  width: 50%;

  margin-right: 10px;
}

.landingPage-submit {
  padding: 11px;
  color: white;
  background-color: #bd9d00;
  outline: none;
  border: none;
}

.lpfooter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 20px 0;
  width: 100%;
  background-color: #dc572e;
}
.lpwidget {
  max-width: 700px;
  padding: 15px;
  margin: 0 auto 15px auto;
  text-align: center;
  font-size: 20px;
}
.social {
  height: 40px;
  width: 40px;
  margin-right: 15px;
}

.lpForm {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 35px;
}

@media only screen and (max-width: 990px) {
  .landingPage-landing-text h1 {
    font-size: 100px !important;
    font-family: "Tangerine", cursive;
    font-weight: 400;
  }
}
@media only screen and (max-width: 600px) {
  .landingPage-landing-text h1 {
    font-size: 80px !important;
    font-family: "Tangerine", cursive;
    font-weight: 400;
  }


  .landingPage-sign-up-button {
    display: none;
  }
  .landingPage-input {
    width: 80vw;
    margin: 15px;
  }
}

@media only screen and (max-width: 700px) and (orientation: landscape) {
  .lpForm {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 500px) {
  .landingPage-landing-text h1 {
    font-size: 60px !important;
  }

  .lpwidget {
    font-size: 18px !important;
  }
}
